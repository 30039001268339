<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Potential Customer information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" :disabled="disabled"
        :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Principal Employee">
            <el-autocomplete class="inline-input addMemberInput" :fetch-suggestions="searchEmployee"
              v-model="form.principalEmployeeId" placeholder="Input Employee Name"
              :trigger-on-focus="false"></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div class="card info-card">
      <el-form ref="employee-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Customer Name">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Company">
            <el-input v-model="form.company"></el-input>
          </el-form-item>
          <el-form-item label="Email Address">
            <el-input v-model="form.emailAddress"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number">
            <el-input v-model="form.phoneNumber"></el-input>
          </el-form-item>
          <el-form-item label="Type">
            <el-select v-model="form.type">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Address" style="width: 75%;">
            <el-input v-model="form.address"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'PotentialCustomerForm',
  props: {
    viewMode: Boolean,
    eventName: String,
  },
  mounted() {
  },
  data() {
    let disabled = false;

    //Default form value
    let form = {
      name: '',
      company: '',
      address: '',
      emailAddress: '',
      phoneNumber: '',
      type: '',
      principalEmployeeId: '',
    };

    //If view mode is enabled, disable the form
    if (this.viewMode === true) {
      disabled = true;
    };

    return {
      disabled,
      form,
      options:[
        {
          value: 'potential',
          label: 'Potential Customer',
        },
        {
          value: 'sns',
          label: 'Potential Customer (SNS)',
        },
        {
          value: 'service',
          label: 'Potential Customer (Service)',
        },
      ],
    };
  },
  methods: {
    startEdit() {
      this.disabled = false;
    },
    async save() {
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
    async searchEmployee(queryString, cb) {
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for (let i = 0; i < employees.length; i++) {
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }
      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  watch: {
    'currentPotentialCustomer': function () {
      this.form = {
        name: this.currentPotentialCustomer.name,
        company: this.currentPotentialCustomer.company,
        address: this.currentPotentialCustomer.address,
        emailAddress: this.currentPotentialCustomer.email_address,
        phoneNumber: this.currentPotentialCustomer.phone_number,
        type: this.currentPotentialCustomer.source,
        principalEmployeeId: this.currentPotentialCustomer.employee_name,
      };
    }
  },
  computed: mapState({
    currentPotentialCustomer: state => state.sales.currentPotentialCustomer,
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.related-user-card {
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form {
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card {
  padding: 30px;
}

.card-title {
  margin-bottom: 30px;
}

.contact-info {
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  .info-card {
    padding-left: 30px;
  }
}

.action-button-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn {
    margin-right: 20px;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
