<template>
  <div class="content-wrapper">
    <div class="customer main-content">
      <div class="page-header">
        <h1>Potential Customer Information</h1>
        <PotentialCustomerForm :viewMode="true" :eventName="'updateCustomer'" @updateCustomer="updateCustomer"/>      
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PotentialCustomerForm from '@/components/sales/PotentialCustomerForm.vue';
import OrderForm from '@/components/sales/OrderForm.vue';
import Common from '@/lib/common';
import Hr from '@/lib/hr';
import Sales from '@/lib/sales';

export default {
  name: 'Customer',
  components: {
    PotentialCustomerForm,
  },
  data(){
    return {
      orders: [],
    }
  },
  mounted(){
    this.loadCustomer();
  },
  methods:{
    async loadCustomer(){
      const loginInfo = Common.getLoginInfo();
      try{
        const customerId = this.$route.params.id;
        const customer = await Sales.loadPotentialCustomer(this.apiUrl, customerId, loginInfo);
        this.setCurrentPotentialCustomer(customer);
      }catch(err){
        console.log(err);
      }
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async getEmployeeByName(memberName){
      const loginInfo = Common.getLoginInfo();
      const searchFields = {
        'employee_name': memberName,
      };
      const employees = await Hr.searchEmployee(this.apiUrl, searchFields, loginInfo);
      const employee = employees[0];
      return employee;
    },
    async updateCustomer(form){
      try{
        const customerInfo = Object.assign({}, form);
        const loginInfo = Common.getLoginInfo();
        const customerId = this.$route.params.id;

        const employee = await this.getEmployeeByName(form.principalEmployeeId);
        const employeeId = employee.id;
        customerInfo.principalEmployeeId = employeeId;
        const customer = await Sales.updatePotentialCustomer(this.apiUrl, customerId, customerInfo, loginInfo);
      }catch(err){
        console.log(err);
      }
    },
    ...mapActions(['setCurrentPotentialCustomer']),
  },
  computed: mapState({
    apiUrl: state => state.apiUrl,
  }),
}
</script>

<style lang="scss">
.page-header{
  h1{
    margin-bottom: 50px;
  }
}
.action{
  a{
    margin-right: 10px;
  }
}
</style>
